@import 'animate.css';
@import 'transitions.css';

.data-react-beautiful-dnd-placeholder {
  position: absolute;
  left: 0;
  width: 300px;
  height: 130px;
  background-color: black;
}

.focus-visible {
  outline: solid 1px white !important;
  border: solid 2px blue !important;
}

.data-focus-visible-added {
  outline: solid 1px white !important;
  border: solid 2px blue !important;
}

/* Used for primary buttons (on a white background) */
.focus-visible-inverted {
  outline: solid 2px blue !important;
  border: solid 1px white !important;
}

/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

button:focus,
input[type='button']:focus {
  outline: none;
}

button:hover,
input[type='button']:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: #005dba;
}

a:hover {
  color: #003366;
}

a:visited {
  color: #2f2d91;
}

* {
  border: 0;
  margin: 0;
  font-family: 'Cabin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.main {
  width: 100%;
  margin: auto;
}

.page-container .content {
  position: relative;
}

.main-app {
  padding: 0px;
  margin: 0px;
  min-width: 1270px;
  font-family: 'Cabin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: #2b3a45;
  height: 100%;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
}

.app-container.windows-scroll ::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 100px;
}

.app-container.windows-scroll ::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.app-container.windows-scroll ::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 100px;
  background-clip: content-box;
  box-shadow: 0 0 0 5px hsla(24, 20%, 50%, 0.4) inset;
  min-height: 20px;
}

.app-container.windows-scroll ::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 100px;
}

.ReactQueryDevtools {
  position: absolute;
}

body {
  background-color: #eff2f5;
  overscroll-behavior: none;
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1300;
}

.modal-overlay.light {
  background-color: rgba(43, 58, 69, 0.55);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

/* targets specifically IE 11 10 */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ms-text-field-flex-item {
    flex-basis: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .ms-text-field {
    display: block !important;
  }
}

.zopim {
  z-index: 1000 !important;
}
